module Http


open Thoth.Json

open Elmish
open Utils
open Fetch.Types
open Fable.Core.JS


let sendFileMultipart url (file : Browser.Types.File) (decoder: Thoth.Json.Decoder<'returnType>) =
    let formData = Browser.Blob.FormData.Create()
    consoleLog("File being uploaded: ", file)
    formData.append("data", file)
    //formData.append("name", file.name)
    let defaultProps =
        [ RequestProperties.Method HttpMethod.POST
          //Fetch.requestHeaders [Fetch.Types.ContentType "multipart/form-data"]
          RequestProperties.Body (formData |> unbox)]
    promise {
        let! fetchResponse = Utils.fetch (url) defaultProps
        //let! fetchResponse = xhrSendFile url formData
        if fetchResponse.Ok then
            let! text = fetchResponse.text()
            let decoded = text |> Decode.fromString decoder
            match decoded with
            | Ok res -> return res
            | Error err -> return! Constructors.Promise.reject<'returnType> err
        else
            let! error = fetchResponse.text()
            return failwith error
    }
    // Aborted attempt: insert these two, use Thot: (sourceEncoder : ('sendType -> Thoth.Json.Encode.Value)) (responseDecoder : (Thoth.Json.Decode.Decoder<'returnType>))

let sendJsonBody<'sendType, 'returnType> (url : string) (encoder : (Encoder<'sendType>)) (decoder : Thoth.Json.Decoder<'returnType>) (payload : 'sendType) : Promise<'returnType> =
    promise {
        let body =
            encoder payload
            |> Encode.toString 2
        consoleLog("Body: ", body)

        let props =
            [ Method HttpMethod.POST
              Fetch.requestHeaders [ HttpRequestHeaders.ContentType "application/json" ]
              RequestProperties.Body (unbox body) ]

        try
            let! result = Utils.fetch url props
            if result.Ok then
                let! textresult = result.text()
                let json = textresult |> Decode.fromString decoder
                match json with
                | Ok success -> return success
                | Error msg -> return! failwithf "Error decoding server response; %A" msg
            else
                let! error = result.text()
                return failwith error
        with _ ->
            return! failwithf "Error sending request to %s." url
    }

let sendRequestWithoutBody<'returnType> url (httpVerb : HttpMethod) (decoder : Thoth.Json.Decoder<'returnType>) : Promise<'returnType> =
    promise {
        let props =
            [ RequestProperties.Method httpVerb
              Fetch.requestHeaders [ HttpRequestHeaders.ContentType "application/json" ] ]
        try
            consoleLog (sprintf "Sending request to url %s" url)
            let! result = Utils.fetch url props
            if result.Ok then
                let! textresult = result.text()
                consoleLog "Decoding text result"
                let json = textresult |> Decode.fromString decoder
                match json with
                | Ok success -> return success
                | Error msg -> return! failwithf "Error decoding server response; %A" msg
            else
                let! error = result.text()
                return failwith error
        with err ->
            return! failwithf "Error sending request to %s (%s)." url (err.ToString())
    }

let sendGetRequest url (decoder: Thoth.Json.Decoder<'returnType>): Promise<'returnType> =
    sendRequestWithoutBody url HttpMethod.GET decoder

let sendDeleteRequest url : Promise<unit> =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.DELETE
              Fetch.requestHeaders [ HttpRequestHeaders.ContentType "application/json" ] ]
        try
            let! response = Utils.fetch url props
            if not response.Ok then
                let! error = response.text()
                return failwith error
        with _ ->
            return! failwithf "Error sending delete request to %s." url
    }