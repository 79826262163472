module Upload

open Elmish
open Global
open Utils
open Http
open Browser.Types
open Fulma.Color

//Types
type UploadStatus = Pending | Uploading | Succeeded of ValidationResult | Failed of System.Exception
type UploadTask = { File: Browser.Types.File; Status: UploadStatus }

type Step =
| SelectFileStep
| UploadingStep
| CompletedStep


type Model =
    { Config: Config; Step: Step ;Tasks: UploadTask list }

type Msg =
| FileDropped of ResizeArray<Browser.Types.File>
| BatchUpload
| RemoveTask of UploadTask
| ClearTasks
| UploadFile of UploadTask
| CancelUpload
| FileUploaded of Result<UploadTask, UploadTask * System.Exception>
| NextUpload
| TaskDetails of UploadTask

//Max number of Concurrent Uploads
let maxUploads = 2

let init (config: Config) =
    let model = { Config = config; Tasks = []; Step = SelectFileStep }
    model, []


let uploadFile (config: Config) (task: UploadTask) =
    promise {
        let! response = Http.sendFileMultipart "/api/upload" task.File (Thoth.Json.Decode.Auto.generateDecoder<ValidationResult>())
        let newTask = { task with Status = Succeeded response }
        return newTask
    }

//State
let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | FileDropped filesArray ->
        let tasks =
            filesArray
            |> List.ofSeq
            |> List.map (fun f -> { File = f; Status = Pending })

        { model with Tasks = tasks }, Cmd.Empty
    | RemoveTask task ->
        match model.Step with
        | SelectFileStep ->
            //Note: not the most performant but not sure that the Javacsript runtime will like recursion
            let taskIndex = model.Tasks |> List.findIndex (fun t -> t = task)
            let newTasks =
                model.Tasks
                |> List.mapi(fun i t -> i,t)
                |> List.filter(fun (i,t) -> i <> taskIndex)
                |> List.map snd
            { model with Tasks = newTasks }, []
        | _ -> model, []
    | ClearTasks ->
        { model with Tasks = []; Step = SelectFileStep }, []
    | BatchUpload ->
        let toast = toastInfo "Upload Started"

        //Generate Tasks
        let uploadFileCmd = Cmd.ofMsg NextUpload

        //Move to UploadFilesStep
        let newModel = { model with Step = UploadingStep; }

        newModel, Cmd.batch [toast;  uploadFileCmd]

    | UploadFile task ->
        let config = model.Config
        let cmd  = Cmd.OfPromise.either
                    (uploadFile config)
                    (task)
                    (FileUploaded << Ok)
                    (fun e -> (task, e) |> Error |> FileUploaded)

        let newTasks = model.Tasks |> List.map(fun t -> if t.File = task.File then { t with Status = Uploading } else t)
        { model with Tasks = newTasks }, cmd

    | CancelUpload ->
        { model with Tasks = []; Step = SelectFileStep }, []

    | FileUploaded (Ok task) ->
        let newTasks = model.Tasks |> List.map (fun t -> if t.File = task.File then task else t)
        { model with Tasks = newTasks }, Cmd.ofMsg NextUpload
    | FileUploaded (Error (task,error)) ->
        let newTask = { task with Status = Failed error }
        let newTasks = model.Tasks |> List.map (fun t -> if t.File = task.File then newTask else t)
        { model with Tasks = newTasks }, Cmd.ofMsg NextUpload
    | NextUpload ->
        let inCompleteTasks = model.Tasks |> List.filter (fun t -> match t.Status with | Succeeded _ | Failed _ -> false | _ -> true )
        match inCompleteTasks with
        | [] ->
            let toast = toastInfo "Uploading Completed"
            { model with Step = CompletedStep }, toast
        | tasks ->
            //Fire next Pending Task
            tasks
            |> List.tryHead
            |> function
            | Some t -> model, Cmd.ofMsg(UploadFile t)
            | None -> model, []
    | TaskDetails task ->

        let validationMessage (feedback: ValidationFeedback list) =
            let validations = feedback |> List.map(fun v -> v.ToString())
            let validationString = System.String.Join("<br/>", validations)
            validationString


        match task.Status with
        | Succeeded validation ->
            let cmd =
                if validation.Errors.Length > 0 then
                    let errorString = validation.Errors |> validationMessage
                    alertError "Upload Failed" errorString
                elif validation.Warnings.Length > 0 then
                    let warningString = validation.Warnings |> validationMessage
                    alertSuccess "Upload Succeeded" warningString
                else
                    alertSuccess "Upload Succeeded" ""
            model, cmd
        | Failed error ->
            eprintfn "%A" error
            let a = alertError "Upload Failed" error.Message
            model, a
        | _ -> model, []


//Views
open Fable.React
open Fable.React.Props

let renderProgressBar pageModel =
    let render step1 step1to2 step2 step2to3 step3 =
        div [ ClassName "progress-status" ]
            [ div [ classList [ "progress__step", true ; "progress__step--active", step1 ] ]
                [ div [ ClassName "progress__step-number" ] [ str "1" ]
                  div [ ClassName "progress__step-label" ] [ str "Select Files" ] ]
              div [ classList [ "progress__separator", true ; "progress__separator--in-progress", step1to2 ; "progress__separator--active", step2 ] ] [ div [ ClassName "line" ] [] ]
              div [ classList [ "progress__step", true ; "progress__step--active", step2 ] ]
                [ div [ ClassName "progress__step-number" ] [ str "2" ]
                  div [ ClassName "progress__step-label" ] [ str "Upload data" ] ]
              div [ classList [ "progress__separator", true ; "progress__separator--in-progress", step2to3 ; "progress__separator--active", step3 ] ] [ div [ ClassName "line" ] [] ]
              div [ classList [ "progress__step", true ; "progress__step--active", step3 ] ]
                [ div [ ClassName "progress__step-number" ] [ str "3" ]
                  div [ ClassName "progress__step-label" ] [ str "Completed" ] ] ]


    match pageModel.Step with
    | SelectFileStep _ -> render true false false false false
    | UploadingStep _ -> render true false true true false
    | CompletedStep _ -> render true false true false true


let uploadingView (model: Model) dispatch =
    let tasks = model.Tasks
    let title =
        match model.Step with
        | UploadingStep -> "Uploading Files"
        | CompletedStep -> "Completed"
        | otherStep -> string otherStep
    let button =
        match model.Step with
        | UploadingStep ->
            button [ ClassName "button is-small is-danger"; OnClick (fun _ -> dispatch CancelUpload ) ]
                   [ i [ ClassName "fas fa-times" ] []; str "Cancel Upload" ]
        | CompletedStep ->
            button [ ClassName "button is-small is-success"; OnClick (fun _ -> dispatch ClearTasks ) ]
                   [ i [ ClassName "fas fa-cloud-upload-alt" ] []; str "Re-Upload" ]
        | _ -> str ""

    let status =
        function
        | Pending -> str "Pending"
        | Uploading -> str "Uploading"
        | Succeeded validation ->
            if validation.Errors.Length > 0 then
                str "Upload Validation Failed"
            elif validation.Warnings.Length > 0 then
                str "Validation succeeded with Warnings"
            else
                str "Upload succeeded"
        | Failed e -> str "Upload Failed"

    let detailsBtn task =
        match task.Status with
        | Succeeded validation ->
            match validation.Errors, validation.Warnings, validation.DatasetId with
            | [], [], Some id ->
                let datasetUrl = sprintf "%s?dataset=%O" model.Config.DataExplorerUrl (id.Unwrap())
                a [ Href datasetUrl; Target "_blank" ] [ str "View Dataset" ]
            | _ -> a [OnClick (fun e ->  dispatch (TaskDetails task))] [ str "Details"]
        | Failed _ ->
            a [OnClick (fun e ->  dispatch (TaskDetails task))] [ str "Details"]
        | _ -> str ""

    div [] [
        div [ ClassName "heading"] [
            div [ ClassName "column" ] [ h4 [] [ str title ] ]
            div [ ClassName "column has-text-right"] [
                button
            ]
        ]
        div [ Style [ Padding "10px 20px"] ] [
            table [ ClassName "table is-fullwidth"] [
                tbody [] [
                    yield tr [] [
                        th [] [str "File Name"]
                        th [ ClassName "has-text-right" ] [ str "File Size" ]
                        th [ ] [ str "Status" ]
                        th [ ClassName "has-text-right"] [ str "" ]
                    ]
                    for task in tasks ->
                        tr [] [
                            td [] [str task.File.name]
                            td [ ClassName "has-text-right" ] [str (sprintf "%0.0fKb" (float task.File.size / 1024.0))]


                            td [ ] [ status(task.Status) ]
                            td [ ClassName "has-text-right" ] [ task |> detailsBtn ]
                        ]
                ]
            ]
        ]
    ]


let selectFilesView (tasks: UploadTask list) dispatch =
    div [] [
        div [ ClassName "heading"] [
            div [ ClassName "column" ] [ h4 [] [ str "Files" ] ]
            div [ ClassName "column has-text-right"] [
                button [ ClassName "button is-small is-primary"; OnClick (fun _ -> dispatch BatchUpload ) ]
                       [ i [ClassName "fas fa-cloud-upload-alt"] []; str "Upload your data" ]
               ]
        ]

        div [ Style [ Padding "10px 20px"] ] [
            table [ ClassName "table is-fullwidth"] [
                tbody [] [
                    yield tr [] [
                        th [] [str "File Name"]
                        th [ ClassName "has-text-right" ] [str "File Size"]
                        td [ ClassName "has-text-right" ] [ a [OnClick (fun _ -> dispatch (ClearTasks))] [str "Remove All"]]
                    ]
                    for task in tasks ->
                    tr [] [
                        td [] [str task.File.name]
                        td [ ClassName "has-text-right" ] [str (sprintf "%0.0fKb" (float task.File.size / 1024.0))]
                        td [ ClassName "has-text-right" ] [ a [OnClick (fun _ -> dispatch (RemoveTask task))] [ str "Remove"]]
                    ]
                ]
            ]
        ]
    ]


let uploadView (model : Model)  dispatch =
    match model.Step with
    | SelectFileStep ->
        match model.Tasks with
        | [] ->
            div [ ClassName "step-container step-container--upload" ] [
                div [ ClassName "upload-form" ] [
                    Components.ReactDropzone.dropzone
                        [
                            Components.ReactDropzone.ClassName "dropzone"
                            Components.ReactDropzone.AcceptClassName "dropzone--accept"
                            Components.ReactDropzone.RejectClassName "dropzone--reject"
                            Components.ReactDropzone.Accept ".xlsx, .zip"
                            Components.ReactDropzone.OnDropAccepted (FileDropped >> dispatch)
                        ]
                        [
                            div []
                                [   div [ ClassName "icon" ] [ i [ ClassName "fas fa-table fa-3x" ] [] ]
                                    div [ ClassName "dropzone__label" ] [ str "Drop your data files here" ]
                                ]
                        ]

                ]
            ]
        | tasks -> selectFilesView tasks dispatch
    | UploadingStep -> uploadingView model dispatch
    | CompletedStep -> uploadingView model dispatch

let view model (dispatch: Msg -> unit) =
    div [] [
        renderProgressBar model
        uploadView model dispatch
    ]