module View


open Types
open Global
open Fable.React
open Fable.React.Props


let headerView =
    header [ ]
        [ nav [ ClassName "navbar"
                Role "navigation"
              ]
              [ div [ ClassName "navbar-brand" ]
                    [
                      a [ ClassName "navbar-item"
                          Href "/" ]
                        [ str "Syngenta" ]
                      div [ ClassName "navbar-item dc-copy-text" ]
                          [ a [ Href "https://www.douglasconnect.com/"
                                Target "_blank" ]
                              [ str "Built by "
                                span [ ClassName "logo--dark" ] [ str "edelweiss" ]
                                span [ ClassName "logo--light" ] [ str "connect" ] ]
                          ]
                    ]
                div [ ClassName "navbar-menu"
                      Id "navbar-menu"
                    ]
                    [ div [ ClassName "navbar-start" ]
                        [ div [ ClassName "navbar-item navbar-tagline" ]
                              [ str "EU-ToxRisk case study"
                              ]
                        ]
                    ]
              ]
        ]



let navigation (model: Model) =
    match model with
    | { State = LoadingConfig } -> str ""
    | { Config = Some { Authentication = None }  } -> str ""
    | _ ->
        div [ ClassName "navigation" ] [
            ul [] [
                li [ classList ["is-active", model.Page = HomePage] ] [
                    a [ Href (Page.ToHash HomePage)] [
                        i [ ClassName "fas fa-home" ] []; str "Home" ]
                ]
                li [ classList ["is-active", model.Page = UploadPage] ] [
                    a [ Href (Page.ToHash UploadPage)] [
                        i [ ClassName "fas fa-cloud-upload-alt" ] []; str "Upload" ]
                ]
                li [] [
                    a [ Href "/auth/logout"] [
                        i [ ClassName "fas fa-user" ] []
                        str "Logout" ]
                ]
            ]
        ]


let loadingView (operation : string) =
    div [ ClassName "step-container step-container--processing" ]
        [ div [ ClassName "progress-indicator" ]
              [ i [ ClassName "fas fa-circle-notch fa-3x fa-spin" ] [ ]
                div [ ClassName "status-label" ] [ str operation ]
              ] ]

let loginView() =
    div [ ClassName "step-container step-container--processing" ]
        [ div [ ClassName "progress-indicator" ] [
            a [ Href "/auth/login"; ClassName "button"] [
                i [ ClassName "fas fa-user" ] []
                str "Login" ] ]
        ]

let view (model : Model) (dispatch : Msg -> unit) =

    let currentView =
        match model with
        | { State = LoadingConfig } -> loadingView "Fetching Configuration"
        | { State = LoginModel } -> loginView()
        | { Page = HomePage; State = HomeModel model } -> Home.view model (dispatch << HomeMsg)
        | { Page = UploadPage; State = UploadModel model } -> Upload.view model (dispatch << UploadMsg)
        | _ -> loadingView "Invalid State"


    let designModeStateButtons =
        let designModeActive = model.Config |> Option.map(fun c -> c.ShowDebugControls) |> Option.defaultValue false
        if designModeActive then
            Design.renderStateButtons model.State dispatch
        else
            str ""


    div [] [
        headerView
        navigation model
        div [ ClassName "app-container" ]
            [ currentView
              designModeStateButtons ]
    ]