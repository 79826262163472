module EdelweissData.Thoth.Identifiers

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base.Identifiers
open Thoth.Json

module InProgressDatasetId =
    let decode : Decoder<InProgressDatasetId> = Decode.guid |> Decode.map InProgressDatasetId
    let encode (InProgressDatasetId id) = Encode.string (id.ToString())

module PublishedDatasetId =
    let decode : Decoder<PublishedDatasetId> = Decode.guid |> Decode.map PublishedDatasetId
    let encode (id : PublishedDatasetId) = Encode.string (id.ToString())

module DatasetId =
    let decode : Decoder<DatasetId> = Decode.guid |> Decode.map DatasetId
    let encode (id : DatasetId) = Encode.string (id.ToString())

module Version =
    let decode : Decoder<Version> = Decode.int |> Decode.map Version
    let encode (version : Version) = Encode.int (version.Unwrap())

module PublishedDatasetIdAndVersion =

    let decode : Decoder<PublishedDatasetIdAndVersion> =
        Decode.object (fun get ->
            { Id = get.Required.Field "id" PublishedDatasetId.decode
              Version = get.Required.Field "version" Version.decode })

    let encode (data : PublishedDatasetIdAndVersion) =
        Encode.object [ "id", PublishedDatasetId.encode data.Id
                        "version", Version.encode data.Version ]

module Url =
    let decode : Decoder<Url> = Decode.string |> Decode.map Url
    let encode (Url token) = Encode.string token

module TermName =
    let decode : Decoder<TermName> =
        Decode.oneOf [ Decode.map TermId Decode.string
                       Decode.map (fun d -> TermId (d.ToString())) Decode.float
                       Decode.nil Null ]

    let encode termName =
        match termName with
        | TermId id -> Encode.string id
        | Null -> Encode.nil

module Email =
    let decode : Decoder<Email> = Decode.string |> Decode.map Email
    let encode (Email email) = Encode.string email

module JsonPath =
    let decode : Decoder<JsonPath> = Decode.string |> Decode.map JsonPath
    let encode (JsonPath jsonPath) = Encode.string jsonPath
